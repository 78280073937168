<template>
  <v-btn 
    depressed
    :block="!noBlock" 
    :class="
    [
      leftFlat ? 'rounded-l-0' : '',
      rightFlat ? 'rounded-r-0' : '',
      topFlat ? 'rounded-t-0' : ''
    ]" 
    :color="white ? 'white' : 'turquesa'" 
    light
    @click.stop="openNewsDialog"
    min-width="150px">
    <v-img class="mr-2" max-width="10px" src="/assets/ic-arrow-blue-right.svg" />
    <span class="font-weight-bold">{{ title }}</span>
    </v-btn>
</template>

<script>
import EventBus from '@/utils/eventbus'
export default {
  name: 'InscribiteButton',
  props: {
    white: {
      type: Boolean,
      default: false
    },
    leftFlat: {
      type: Boolean,
      default: false
    },
    rightFlat: {
      type: Boolean,
      default: false
    },
    topFlat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'INSCRIBITE'
    },
    noBlock: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openNewsDialog() {
      EventBus.$emit('show-news-dialog',{ title: 'INSCRIBITE', withSoon: true})
    }
  }
}
</script>

<style>

</style>